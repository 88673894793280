import React from 'react';
import {Link} from "react-router-dom";

const Slider = () => {
    return (
        <div className="slider_area">
            <div className="slider_active">
                <div className="single_slider  d-flex align-items-center slider_bg_1">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-12">
                                <div className="slider_text ">
                                    <h3>Оқушының дәптерін <br/>
                                        мұғалімсіз онлайн <br/>
                                        тексереміз</h3>
                                    <Link to="/checker" className="boxed-btn3">Тексерту</Link>
                                    <Link to="/about" className="boxed-btn4">Біз жайлы</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Slider;