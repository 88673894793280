import React from 'react';
import {Link} from "react-router-dom";

const LatestCourses = () => {
    return (
        <div className="latest_coures_area">
            <div className="latest_coures_inner">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="coures_info">
                                <div className="section_title white_text">
                                    <h3>Бұл жүйе не үшін?</h3>
                                    <p>Мұғалімнің жұмысын жеңілдету үшін және қатесіз, <br/> тиімді және жылдам тексереді </p>
                                </div>
                                <div className="coures_wrap d-flex">
                                    <div className="single_wrap mr-5">
                                        <div className="icon">
                                            <i className="flaticon-lab"></i>
                                        </div>
                                        <h4>Қазақ тілі</h4>
                                        <p>Осы пәнге байланысты <br/> қателерді тексеру</p>
                                        <Link to="/checker" className="boxed-btn5">Тексеру</Link>
                                    </div>
                                    <div className="single_wrap">
                                        <div className="icon">
                                            <i className="flaticon-lab"></i>
                                        </div>
                                        <h4>Диктант</h4>
                                        <p>Диктантты да тексеруге <br/> арналған модель</p>
                                        <Link to="/checker" className="boxed-btn5">Тексеру</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default LatestCourses;