import React from 'react';

const ServiceArea = () => {
    return (
        <div className="service_area gray_bg">
            <div className="container">
                <div className="row justify-content-center ">
                    <div className="col-lg-4 col-md-6">
                        <div className="single_service d-flex align-items-center ">
                            <div className="icon">
                                <i className="flaticon-school"></i>
                            </div>
                            <div className="service_info">
                                <h4>Өте</h4>
                                <p>жылдам</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single_service d-flex align-items-center ">
                            <div className="icon">
                                <i className="flaticon-error"></i>
                            </div>
                            <div className="service_info">
                                <h4>Өте</h4>
                                <p>тиімді</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-md-6">
                        <div className="single_service d-flex align-items-center ">
                            <div className="icon">
                                <i className="flaticon-book"></i>
                            </div>
                            <div className="service_info">
                                <h4>Өте</h4>
                                <p>бағалы</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ServiceArea;