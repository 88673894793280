import React from 'react';
import Header from "./components/landing/Header";
import Footer from "./components/landing/Footer";
import Home from "./pages/Home";
import About from "./pages/About";
import Contact from "./pages/Contact";

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Checker from "./pages/Checker";


function App() {
  return (
      <Router>
        <Header />
        <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Contact />} />
            <Route path="/checker" element={<Checker />} />
        </Routes>
        <Footer />
      </Router>
  );
}

export default App;
