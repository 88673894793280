import React from 'react';
import {styled} from "styled-components";

const About = () => {
    return (
        <StyledContainer className="container">
            <legend>Жоба жайлы</legend>
            <hr/>
            <p>Жоба жайлы ақпараттар осында болады</p>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    height: 400px;
    padding: 30px 0;
`;

export default About;