import React from 'react';
import {Link} from "react-router-dom";
import { styled } from 'styled-components';

const Header = () => {
    return (
        <header>
            <div className="header-area ">
                <div className="header-top_area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="header_top_wrap d-flex justify-content-between align-items-center">
                                    <div className="text_wrap">
                                        <p><span>+7 776 228 27 81</span> <span>info@notebook-checker.zilla.kz</span></p>
                                    </div>
                                    <div className="text_wrap">
                                        <p>
                                            <Link to="/login"> <i className="ti-user"></i> Жүйеге кіру</Link>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="sticky-header" className="main-header-area">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="header_wrap d-flex justify-content-between align-items-center">
                                    <div className="header_left">
                                        <div className="logo">
                                            <Link to="/">
                                                <StyledLogo>Дәптер тексеру</StyledLogo>
                                            </Link>
                                        </div>
                                    </div>
                                    <div className="header_right d-flex align-items-center">
                                        <div className="main-menu  d-none d-lg-block">
                                            <nav>
                                                <ul id="navigation">
                                                    <li><Link to="/">Басты бет</Link></li>
                                                    <li><Link to="/about">Жоба жайлы</Link></li>
                                                    <li><Link to="/contact">Байланысу</Link></li>
                                                </ul>
                                            </nav>
                                        </div>
                                        <div className="Appointment">
                                            <div className="book_btn d-none d-lg-block">
                                                <Link data-scroll-nav='1' to="/checker">Тексеру</Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="mobile_menu d-block d-lg-none"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};

const StyledLogo = styled.div`
    font-size: 30px;
    font-weight: bold;
    color: #234262;
`;

export default Header;