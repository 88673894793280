import React from 'react';
import {styled} from "styled-components";

const Contact = () => {
    return (
        <StyledContainer className="container">
            <legend>Бізбен байланысу</legend>
            <hr/>
            <p>Бізбен байланысу үшін мына телефонға хабарласыңыз: +7 776 228 27 81</p>
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    height: 400px;
    padding: 30px 0;
`;

export default Contact;