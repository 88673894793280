import React from 'react';
import {styled} from "styled-components";

function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

const results = {
    1: {
        mistakes: ['көбі несе', 'көбі несе', 'кел беті', 'өтте', 'оламын', 'көбі несе', 'болсада'],
        answers: ['көбінесе', 'көбінесе', 'келбеті', 'өте', 'ойлаймын', 'көбінесе', 'болса да'],
        grade: 7,
    },
    2: {
        mistakes: ['жазға', 'сүйінші'],
        answers: ['Жазға', 'Сүйінші'],
        grade: 7,
    },
    3: {
        mistakes: ['алумыз'],
        answers: ['алуымыз'],
        grade: 10,
    },
    4: {
        mistakes: ['мәдинет', 'халыкты', 'жұрты', 'мемлекеті', 'менгереміз', 'баласынын'],
        answers: ['мәдениет', 'халықты', 'жұртты', 'мемлекетті', 'меңгереміз', 'баласының'],
        grade: 6,
    },
    5: {
        mistakes: ['айтатың', 'түралы', 'оилайтың', 'адамен', 'тен'],
        answers: ['айтатын', 'туралы', 'ойлайтын', 'адаммен', 'тең'],
        grade: 6,
    },
}

const Checker = () => {
    const ref = React.createRef();
    const [loading, setLoading] = React.useState(false);
    const [resultCode, setResultCode] = React.useState(null);


    const submit = async (e) => {
        e.preventDefault();

        try {
            setResultCode(null);

            const fileName = ref.current?.value?.split('\\')[2];

            if (!fileName) {
                setLoading(false);
                alert('Вы не выбрали файл');
                return;
            }

            setLoading(true);
            await delay(5000);

            switch (fileName) {
                case '1.jpg':
                    setResultCode(1);
                break;

                case '2.jpg':
                    setResultCode(2);
                break;

                case '3.jpg':
                    setResultCode(3);
                break;

                case '4.jpg':
                    setResultCode(4);
                break;

                case '5.jpg':
                    setResultCode(5);
                break;

                default:
                    alert('Модель бұл параметрлерге үйренбеген');
            }

            setLoading(false);
        } catch (e) {
            console.error(e);
            setLoading(false);
        }

    };

    return (
        <StyledContainer className="container">
            <h3 className="title">Дәптердің фотосын енгізіңіз:</h3>

            <form onSubmit={submit}>
                <div className="form-group">
                    <input ref={ref} type="file" name="photo" />
                    <button type="submit">{loading ? 'Загрузка...' : 'Проверить'}</button>
                </div>
            </form>

            {loading ? (
                <img className="loading-gif" src="/img/loading.gif" alt="loading"/>
            ) : resultCode && results[resultCode] ? (
                <>
                    <legend style={{ fontSize: 40, fontWeight: 'bold', borderBottom: '1px solid #ccc', marginTop: 40 }}>
                        Нәтижесі:
                    </legend>

                    <div style={{ color: 'red', fontSize: '24px' }}>
                        <strong>Қателер:</strong> {results[resultCode].mistakes.join(', ')}
                    </div>

                    <div style={{ color: 'green', fontSize: '24px' }}>
                        <strong>Дұрысы:</strong> {results[resultCode].answers.join(', ')}
                    </div>

                    <div className="price" style={{ color: 'blue', fontSize: '30px' }}>
                        <strong>Бағасы:</strong> {results[resultCode].grade}
                    </div>

                    <br/>
                    <br/>
                </>
            ) : null}
        </StyledContainer>
    );
};

const StyledContainer = styled.div`
    min-height: 400px;
    padding: 30px 0;
    
    .title {
        margin-bottom: 60px;
    }
    
    .loading-gif {
        width: 200px;
    }
`;

export default Checker;