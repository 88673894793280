import React from 'react';

const RecentEvents = () => {
    return (
        <div className="recent_event_area section__padding">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-8 col-md-10">
                        <div className="section_title text-center mb-70">
                            <h3 className="mb-45">Жүйе жайлы семинарлар</h3>
                            <p>Біздің ортаға қосыл да, жүйені толығырақ біліп, үлесіңді қос!</p>
                        </div>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-lg-10">
                        <div className="single_event d-flex align-items-center">
                            <div className="date text-center">
                                <span>30</span>
                                <p>Қыркүйек, 2024</p>
                            </div>
                            <div className="event_info">
                                <a href="#">
                                    <h4>Алғашқы танысу</h4>
                                </a>
                                <p><span> <i className="flaticon-clock"></i> 10:30</span> <span> <i
                                    className="flaticon-calendar"></i> 30 қыр. 2024 </span> <span> <i
                                    className="flaticon-placeholder"></i> Жетісу облысы</span></p>
                            </div>
                        </div>
                        <div className="single_event d-flex align-items-center">
                            <div className="date text-center">
                                <span>03</span>
                                <p>Қараша, 2024</p>
                            </div>
                            <div className="event_info">
                                <a href="#">
                                    <h4>Жүйенің алгоритмі</h4>
                                </a>
                                <p><span> <i className="flaticon-clock"></i> 10:30</span> <span> <i
                                    className="flaticon-calendar"></i> 03 қараша 2024 </span> <span> <i
                                    className="flaticon-placeholder"></i> Жетісу облысы</span></p>
                            </div>
                        </div>
                        <div className="single_event d-flex align-items-center">
                            <div className="date text-center">
                                <span>10</span>
                                <p>Желтоқтан, 2024</p>
                            </div>
                            <div className="event_info">
                                <a href="#">
                                    <h4>Басты семинар</h4>
                                </a>
                                <p><span> <i className="flaticon-clock"></i> 10:30</span> <span> <i
                                    className="flaticon-calendar"></i> 10 желтоқсан 2024 </span> <span> <i
                                    className="flaticon-placeholder"></i> Жетісу облысы</span></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default RecentEvents;