import React from 'react';
import Slider from "../components/landing/Slider";
import ServiceArea from "../components/landing/ServiceArea";
import LatestCourses from "../components/landing/LatestCourses";
import RecentEvents from "../components/landing/RecentEvents";

const Home = () => {
    return (
        <>
            <Slider />
            <ServiceArea />
            <LatestCourses />
            <RecentEvents />
        </>
    );
};

export default Home;