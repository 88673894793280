import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer_top" style={{ paddingBottom: 0 }}>
                <div className="container">
                    <div className="newsLetter_wrap">
                        <div className="row justify-content-between">
                            <div className="col-md-7">
                                <div className="footer_widget">
                                    <h3 className="footer_title">
                                        Бізге жазылу
                                    </h3>
                                    <form action="#" className="newsletter_form">
                                        <input type="text" placeholder="Email мекен жайыңыз"/>
                                        <button type="submit">Жазылу</button>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-12 col-lg-5">
                                <div className="footer_widget">
                                    <h3 className="footer_title">
                                        Бізді бақылаңыз
                                    </h3>
                                    <div className="socail_links">
                                        <ul>
                                            <li>
                                                <a href="#">
                                                    <i className="ti-facebook"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa fa-twitter"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa fa-google-plus"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="#">
                                                    <i className="fa fa-linkedin"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="copy-right_text">
                <div className="container">
                    <div className="footer_border"></div>
                    <div className="row">
                        <div className="col-xl-12">
                            <p className="copy_right text-center">
                                <p>
                                    Copyright &copy; 2024 Барлық құқық сақталған
                                </p>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;